import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SyntheticEvent, useState } from "react";
// import { Helmet } from 'react-helmet-async';
import './App.css';

import { Provider } from "react-redux";
import store from "./store/store";

import Header from './components/header/Header';
import HomePage from './pages/homePage/HomePage';
import NotFoundPage from './pages/notFoundPage/NotFoundPage';
import Bottom from "./components/bottom/Bottom";
import ContactPage from "./pages/contactPage/ContactPage";
import DetailPage from "./pages/detailPage/DetailPage";
import Privacy from './pages/privacy/privacy';
import Terms from './pages/terms/terms';

export interface FilterResult {
  spotName: string,
  duration: string,
  description: any,
  airportDist: number,
  depAirportIata: string,
  arrAirportIata: string,
  price: number,
  flights: any,
  score: number,
  region: string,
}
function App() {
  const [skyType, setSkyType] = useState(0);
  const handleOnSkyTypeChange = (event: SyntheticEvent, newValue: number) => {
    setSkyType(newValue)
  }
  const [filterResult, setFilterResult] = useState<FilterResult[]>([])
  const handleSearchClick = (data: FilterResult[]) => {
    setFilterResult(data)
    
  }

  return (
    <Provider store={store}>
      <div>
        <Router basename={process.env.PUBLIC_URL}>
          <Header skyType={skyType} onSkytypeChange={handleOnSkyTypeChange} onSearchClick={handleSearchClick}/>
          <div>
            <Routes>
              <Route path="/" element={<HomePage skyType={skyType} filterResult={filterResult} />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/detail/:num" element={<DetailPage />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/privacy_policy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </div>
          <Bottom />
        </Router >
      </div >
    </Provider>
  );
}

export default App;
