


export const HOST_API = process.env.REACT_APP_HOST_API_KEY || "";
export interface SkyRider {
    spotName: string,
    duration: string,
    picture: string,
    description: string,
    airportDist: number,
    depAirportIata: string,
    arrAirportIata: string,
    region: string
}
export const skyRidersData = [
    {
        spotName: "Fuerteventura",
        duration: "11h59",
        picture: "assets/images/fullResSpots/fuerteventura.jpg",
        description: `Started his hearted any civilly. So me by marianne admitted speaking. Men bred fine call ask. 
        Cease one miles truth day above seven. Suspicion sportsmen provision suffering mrs saw engrossed something. Snug soon he on plan in be dine some.`,
        airportDist: 89.502619,
        depAirportIata: "MRS",
        arrAirportIata: "PWO",
        region: "Europe"
    }
]
