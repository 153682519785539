import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterMemoState {
  depAirport: string;
  minWind: number;
  maxWind: number;
  minTemp: number;
  maxTemp: number;
  minPrice: number;
  maxPrice: number;
  airportDist: number;
  region: string[];
  depDate: string;
  retDate: string;
}

const initialState: FilterMemoState = {
  depAirport: '',
  region: [],
  minWind: 12,
  maxWind: 28,
  minTemp: 10,
  maxTemp: 40,
  minPrice: 0,
  maxPrice: 10000,
  airportDist: 300,
  depDate: '',
  retDate: '',
};

const filterMemoSlice = createSlice({
  name: 'filterMemo',
  initialState,
  reducers: {
    // Use a generic to enforce the value type to match the key
    updateFilterMemoValue: <K extends keyof FilterMemoState>(
      state: FilterMemoState,
      action: PayloadAction<{ name: K; value: FilterMemoState[K] }>
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  }
});

export const { updateFilterMemoValue } = filterMemoSlice.actions;
export default filterMemoSlice.reducer;
