import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  depAirport: string;
  minWind: number;
  maxWind: number;
  minTemp: number;
  maxTemp: number;
  minPrice: number;
  maxPrice: number;
  airportDist: number;
  region: string[];
  depDate: string;
  retDate: string;
}

const initialState: AppState = {
  depAirport: '',
  region: [],
  minWind: 12,
  maxWind: 28,
  minTemp: 10,
  maxTemp: 40,
  minPrice: 0,
  maxPrice: 10000,
  airportDist: 300,
  depDate: '',
  retDate: '',
};

const inputSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    // Use a generic to enforce the value type to match the key
    updateInputValue: <K extends keyof AppState>(
      state: AppState,
      action: PayloadAction<{ name: K; value: AppState[K] }>
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  }
});

export const { updateInputValue } = inputSlice.actions;
export default inputSlice.reducer;
