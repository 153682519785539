import React, { FormEvent, SyntheticEvent, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { updateInputValue } from '../../store/filterReducer';
import { updateLoadingState } from '../../store/loadingReducer';
import { RootState } from '../../store/store';

import { styled, useTheme, Box, Stack, Grid, Typography, Tabs, Tab, TextField, Button } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { FilterResult } from "../../App";
import { HOST_API } from "../../config";

import "./Header.css";
import { updateFilterResult } from "../../store/resultReducer";
import { updateFilterMemoValue } from "../../store/filterMemoReducer";
import { updatesearchState } from "../../store/searchReducer";



interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: SyntheticEvent, newValue: number) => void;
}


const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'skyblue',
  },
});

interface StyledTabProps {
  label: string;
}

interface Airport {
  icao: string;
  iata: string | null;
  name: string;
  city: string;
  state: string;
  country: string;
  elevation: number;
  lat: number;
  lon: number;
  tz: string;
}

type props = {
  skyType: number;
  onSkytypeChange: (event: React.SyntheticEvent, newValue: number) => void;
  onSearchClick: (newValue: FilterResult[]) => void;
}
const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  padding: 0,
  color: 'rgba(255, 255, 255, 0.2)',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };
  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].name}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach(
    (item: React.ReactElement & { children?: React.ReactElement[] }, index) => {
      if (index < 6) {
        itemData.push(item);
        itemData.push(...(item.children || []));
      }
    },
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 40 : 40;

  const getChildSize = (child: React.ReactElement) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0) - 20;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 5 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});


const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

interface Position {
  name: string;
  iata: string | null;
}
interface Region {
  value: string;
  slug: string;
}

const Header: React.FC<props> = (props) => {

  const location = useLocation();
  const currentPath = location.pathname
  const dispatch = useDispatch();
  const filterData = useSelector((state: RootState) => state.filter);
  const resultData = useSelector((state: RootState) => state.result);
  const filterMemoData = useSelector((state: RootState) => state.filterMemo);

  console.log(filterData, "filterData");
  const detailNum = Number(location.pathname.split("/detail/")[1]) - 1;
  const [positionData, setPositionData] = useState<Position[]>([])
  // const [filterResult, setFilterResult] = useState<FilterResult[]>([]);
  var demoConstructed: boolean = false

  const regionData: Region[] =
    [
      { value: 'Europe', slug: 'Europe' },
      { value: 'South America', slug: 'South_America' },
      { value: 'Africa', slug: 'Africa' },
      { value: 'Asia', slug: 'Asia' },
      { value: 'Australia & New Zealand', slug: 'Australia_New_Zealand' },
      { value: 'Carribean', slug: 'Carribean' },
      { value: 'Central America', slug: 'Central_America' },
      { value: 'Middle East', slug: 'Middle_East' },
      { value: 'North America', slug: 'North_America' },
      { value: 'Oceania', slug: 'Oceania' },
    ];

  const [displayApiCheck, setDisplayApiCheck] = React.useState(true)

  const handleCheck = async () => {
    if (!displayApiCheck){
      return;
    }
    else{
      // demoConstructed = true
      const checkEndpoint = 'https://kitetripscanner.com/api/check';
      try {
        var checkResponse = await fetch(checkEndpoint).then(res => {
          return res.text();
        }).then(data => {
          dispatch(updateLoadingState(false));
          return data;

        });

        props.onSearchClick(JSON.parse(checkResponse));
        dispatch(updateFilterResult(JSON.parse(checkResponse)));
      }catch (error) {
        console.log('Error during form submission:', error);
      }

      setDisplayApiCheck((display) => {return false})
    }
  }

  handleCheck()

  const MenuProps = {
    PaperProps: {
      style: {
        // width: '220px'
        // maxHeight: '200px',
      },
    },
  };

  const today = dayjs();
  const minDepDate = today.add(1, 'day');

  const depDate = dayjs(filterData.depDate);
  const minRetDate = depDate.add(1, 'day');

  React.useEffect(() => {
    const loadPositionData = async () => {
      try {
        const response = await fetch('/assets/airports.json');
        const data: { [key: string]: Airport } = await response.json();
        let airportNames: Position[] = [];
        Object.values(data).map((airport) => airport.iata && (airportNames.push({ name: `${airport.name}, ${airport.tz}`, iata: airport.iata })));
        setPositionData(airportNames);
      } catch (error) {
        console.error('Error loading position data:', error);
      }
    };

    loadPositionData();
  }, []);

  // search form submit
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(updatesearchState(true));
    const regionSlug =
      filterData.region.map((item, index) =>
        item.replace(/ &/g, '').replace(/ /g, '_')
      ).toString();

    const queryParams = new URLSearchParams({
      minWind: filterData.minWind.toString(),
      maxWind: filterData.maxWind.toString(),
      minTemp: filterData.minTemp.toString(),
      maxTemp: filterData.maxTemp.toString(),
      regions: regionSlug,
      depDate: filterData.depDate,
      retDate: filterData.retDate,
      depAirport: filterData.depAirport || '',
      airportDist: filterData.airportDist.toString() || "",
      minPrice: filterData.minPrice.toString(),
      maxPrice: filterData.maxPrice.toString()
    });
    const endpoint = `${HOST_API}/api?${queryParams.toString()}`;
    dispatch(updateLoadingState(true));
    try {
      const response = await fetch(endpoint).then(res => {
        return res.text();
      }).then(data => {
        dispatch(updateLoadingState(false));
        return data;

      });
      console.log("response", response)

      // const validJsonData = JSON.parse(response.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2": '));

      console.log(JSON.parse(response));
      // setFilterResult(validJsonData);
      props.onSearchClick(JSON.parse(response));
      dispatch(updateFilterResult(JSON.parse(response)));
    } catch (error) {
      console.log('Error during form submission:', error);
    }
  }

  const handleFilterChange = (event: React.SyntheticEvent, newValue: any) => {
    console.log('position', newValue)
    const actionPayload: any = {
      name: "depAirport",
      value: newValue ? newValue.iata : ''
    };
    const actionMemoPayload: any = {
      name: "depAirport",
      value: newValue ? newValue.name : ''
    };
    dispatch(updateInputValue(actionPayload));
    dispatch(updateFilterMemoValue(actionMemoPayload));
  }

  const handleDateChange = (name: string) => (newValue: Dayjs | null) => {
    const actionPayload: any = {
      name: name,
      value: newValue ? newValue.format('YYYY-MM-DD') : ""
    };
    dispatch(updateInputValue(actionPayload));
    dispatch(updateFilterMemoValue(actionPayload));
  }

  const handleRegionChange = (event: SelectChangeEvent<typeof filterData.region>) => {
    const {
      target: { value },
    } = event;
    console.log(value)
    const actionPayload: any = {
      name: 'region',
      value: typeof value === 'string' ? value.split(',') : value
    };
    dispatch(updateInputValue(actionPayload));
  };

  return (
    <Box sx={{
      backgroundImage: 'linear-gradient(135deg, #33004A, #0E0958)',
      px: { xs: 2, md: 4 },
      '& .MuiTabs-indicatorSpan': {
        backgroundColor: '#00C6EE !important'
      }
    }}>
      <Stack sx={{ maxWidth: "1400px", mx: "auto" }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
          <Box sx={{ '& a': { fontWeight: 'bold', fontSize: "28px", textDecoration: 'none' } }}>
            <Link color="white" to="/" style={{ display: "flex" }}>
              <Box component='img' width="240px" src="/logo/logo_kitetripscanner.svg" />
            </Link>
          </Box>
          <Box sx={{ display: 'flex' }}>
            {/* <Box sx={{ px: 2 }}>
              <Link color="white" to="/about" style={{ color: "white" }}>ABOUT</Link>
            </Box> */}
            <Box>
              <Link to="/contact" style={{ color: "white", textDecoration: "none" }}>CONTACT</Link>
            </Box>
          </Box>
        </Stack>
        <Box>
          <StyledTabs
            value={props.skyType}
            onChange={props.onSkytypeChange}
            aria-label="styled tabs example"
            sx={{ width: 'fit-content', mb: { xs: 0, md: 3 }, mx: 'auto' }}
          >
            {/*<StyledTab label="KITESURF" sx={{ minWidth: 'fit-content', fontSize: '22px', fontWeight: 'bold' }} /> */}
            {/* <StyledTab label="SURF" sx={{ minWidth: 'fit-content', mr: 3, fontSize: '22px', fontWeight: 'bold' }} /> */}
            {/* <StyledTab label="SKI" sx={{ minWidth: 'fit-content', fontSize: '22px', fontWeight: 'bold' }} /> */}
          </StyledTabs>
        </Box>
        {
          currentPath === '/' &&
          <Box mb={4}>
            <Box component="form" method="post" onSubmit={handleSubmit}>
              <Stack flexDirection="row" sx={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}>
                <Stack width="100%" mr={{ xs: 0, lg: 1 }} mt={3} sx={{
                  mb: { xs: 2, lg: 0 },
                  '& .MuiOutlinedInput-root': { backgroundColor: '#fff', borderRadius: '4px' },
                  '& fieldset': { border: 'none !important' },

                }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} lg={4} >
                      {/* <input type="text" value={searchData.position} onChange={handleFilterChange} /> */}
                      <Stack>
                        <Typography variant="body2" component='p' color='white' mb={1}>From</Typography>
                        <Box>
                          <Autocomplete
                            disableListWrap
                            value={positionData.find((option) => option.name === filterMemoData.depAirport) || null}
                            onChange={handleFilterChange}
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            options={positionData}
                            // isOptionEqualToValue={(option, value) => option === value}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) =>
                            (<TextField
                              {...params}
                              variant="outlined"
                              name="position"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FlightTakeoffIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />)
                            }
                            renderOption={(props, option, state) =>
                              [props, option, state.index] as React.ReactNode
                            }
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} sx={{
                      mb: { xs: 1, lg: 0 }
                    }}>
                      <Stack sx={{
                        mt: "20px",
                      }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <Stack flexDirection="row" sx={{ backgroundColor: 'white', borderRadius: "4px" }} >
                              <DemoItem>
                                <DatePicker
                                  name="depDate"
                                  format="DD/MM/YYYY"
                                  value={dayjs(filterMemoData.depDate)} 
                                  minDate={minDepDate}
                                  onChange={handleDateChange("depDate")}
                                />
                              </DemoItem>
                              <DemoItem >
                                <DatePicker
                                  name="retDate"
                                  format="DD/MM/YYYY"
                                  value={dayjs(filterMemoData.retDate)} 
                                  minDate={minRetDate}
                                  onChange={handleDateChange("retDate")} />
                              </DemoItem>
                            </Stack>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Stack>
                        <Typography variant="body2" component="p" color="white" mb={1}>Preferred region</Typography>
                        <Box>
                          <FormControl sx={{ width: '100%' }}>
                            <Select
                              multiple
                              displayEmpty
                              value={filterData.region}
                              onChange={handleRegionChange}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <span>Any</span>;
                                }

                                return selected.join(', ');
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ 'aria-label': 'Without label' }}
                              sx={{ width: '100%' }}
                            >
                              {regionData.map((region, index) => (
                                <MenuItem key={index} value={region.value}>
                                  <Checkbox checked={filterData.region.indexOf(region.value) > -1} sx={{ py: '2px' }} />
                                  <ListItemText primary={region.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} lg={1}>
                      <Button type="submit" variant="contained" disableElevation
                        sx={{
                          width: '100%', height: '56px',
                          mx: 'auto',
                          mt: '28px',
                          backgroundColor: "#00C6EE!important",
                          fontWeight: 'bold'
                        }}>
                        Search
                      </Button>
                    </Grid>
                  </ Grid>
                </Stack>
              </Stack>
            </Box>
          </Box>
        }
        {
          currentPath.indexOf("detail") > -1 &&
          <Box textAlign="center" my={4}>
            <Typography variant="h3" component="h2" color="#fff" >{resultData[detailNum].spotName}</Typography>
          </Box>
        }
      </Stack >
    </Box >
  );
}

export default Header;




